'use client';

import {
    useDecodeJwt,
    useGetChargerStatus,
    useGetCmsHistoryByUser,
    useGetLatestCmsHistoryByCharger,
    useLogout,
    useStartCharging,
    useStopCharging,
} from '@/app/hooks/cms';
import { structUrl } from '@/app/utils/helpers';
import { useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';
import ChargingPage from './charging';
import ChargingDonePage from './charging-done';
import MessagePage from './message';
import TokenReadyPage from './token-ready';

let interval: any;

const AppPage = () => {
    const params = useSearchParams();
    const router = useRouter();
    const [stopCountDown, setStopCountDown] = useState<number>(10);
    const carpark = params.get('carpark') || undefined;
    const charger = params.get('charger') || undefined;
    const mode = params.get('mode') || undefined;
    const homePath = structUrl('/', {
        carpark,
        charger,
    });

    const { data: chargerStatus, isError: chargerStatusError } =
        useGetChargerStatus({
            charger,
            carpark,
        });

    const connector = chargerStatus?.[0]?.connectors[0];
    const connectorStatus = connector?.connectorStatus;
    const connectorNo = connector?.connectorNo;

    const { data: userObj, isLoading: userObjLoading } = useDecodeJwt();
    const { mutate: logout, isSuccess: logoutSuccess } = useLogout();
    const { data: userCmsHistory } = useGetCmsHistoryByUser({
        token: userObj?.token,
    });
    const { data: latestChargerHistory } = useGetLatestCmsHistoryByCharger({
        carpark: carpark || '',
        charger: charger || '',
        connector: connectorNo ? `${connectorNo}` : '',
    });
    const catPlateNo = userObj?.user?.car_plate_no || null;

    const {
        mutate: startCharging,
        reset: startChargingReset,
        status: startChargingStatus,
        error: startChargingError,
        isSuccess: startChargingSuccess,
    } = useStartCharging({
        chargerId: charger || '',
        carparkId: carpark || '',
        connectorNo: connectorNo ? `${connectorNo}` : '',
    });

    const {
        mutate: stopCharging,
        reset: stopChargingReset,
        status: stopChargingStatus,
        error: stopChargingError,
        isSuccess: stopChargingSuccess,
        data: stopChargingData,
    } = useStopCharging({
        chargerId: charger || '',
        carparkId: carpark || '',
        connectorNo: connectorNo ? `${connectorNo}` : '',
    });

    const totalDurationRounded = Math.floor(
        (userCmsHistory?.totalDuration || 0) / 3600
    );

    const rewardCount = totalDurationRounded % 30 / 30 * 10;
    const giftCount = Math.floor(totalDurationRounded / 10);

    const goToHomePage = useCallback(() => {
        router.push(homePath);
    }, [homePath]);

    const startCountDown = () => {
        if (interval) {
            setStopCountDown(15);
            clearInterval(interval);
        }

        interval = setInterval(() => {
            setStopCountDown((cd) => {
                if (cd <= 1) {
                    clearInterval(interval);
                    return 0;
                }
                return cd - 1;
            });
        }, 1000);
    };

    useEffect(() => {
        if (logoutSuccess) goToHomePage();
    }, [logoutSuccess])

    useEffect(() => {
        if (startChargingSuccess) startCountDown();
    }, [startChargingSuccess]);

    useEffect(() => {
        startCountDown();
    }, []);

    useEffect(() => {
        if (!userObjLoading && userObj === null) {
            alert('Please login before charging');
            goToHomePage();
            return;
        }
    }, [userObj, userObjLoading]);

    /**
     * TODO : Check why it will restart the charging when the connector is suspended
     */
    useEffect(() => {
        if (connectorStatus === 'SuspendedEVSE') {
            startChargingReset();
        }
    }, [connectorStatus]);

    // useEffect(() => {
    //     if (startChargingError) {
    //         alert('Start Charging Error');
    //     }
    // }, [startChargingError]);

    useEffect(() => {
        if (stopChargingError) {
            alert('Stop Charging Error');
        }
    }, [stopChargingError]);

    if (!carpark || !charger || chargerStatusError) {
        return (
            <MessagePage
                message="Invalid carpark or charger"
                description={`Please scan the QR code on the charger to start charging.\nIf the error still persists, please contact customer service or close the page to try again.`}
                // href={homePath}
            />
        );
    }

    if (!connector) {
        return (
            <MessagePage
                message={
                    mode === 'register'
                        ? 'Congratulations! Welcome to Volvo!'
                        : 'Welcome back!'
                }
                loading
            />
        );
    }

    if (
        (connectorStatus === 'Finishing' && stopChargingSuccess) ||
        (connectorStatus === 'Charging' && stopChargingSuccess)
    ) {
        return (
            <MessagePage
                message="Charger finishing"
                description="Please unplug the charger."
                loading
            />
        );
    }

    if (connectorStatus === 'Available' && stopChargingSuccess) {
        return (
            <ChargingDonePage
                duration={stopChargingData.duration || 0}
                rewardCount={rewardCount}
                handleRestart={() => {
                    startChargingReset();
                    stopChargingReset();
                }}
                onRedeem={() => {
                    router.push(structUrl('/redeem', { carpark, charger }));
                }}
                handlelogout={logout}
            />
        );
    }

    if (
        connectorStatus === 'Finishing' ||
        connectorStatus === 'SuspendedEVSE' ||
        stopChargingStatus === 'pending'
    ) {
        return <MessagePage message="Charger finishing" loading />;
    }

    if (
        startChargingStatus === 'pending' ||
        (startChargingSuccess && connectorStatus === 'Preparing') // Started by not fetched the data yet
    ) {
        return <MessagePage message="Charger preparing" loading />;
    }

    if (connectorStatus === 'Unavailable' || connectorStatus === 'Error') {
        return (
            <MessagePage
                message="Charger is unavailable"
                description="Please contact customer service or try again later."
                href={homePath}
            />
        );
    }

    if (
        connectorStatus === 'Charging' &&
        latestChargerHistory !== undefined &&
        latestChargerHistory?.username !== userObj?.user.username
    ) {
        return (
            <MessagePage
                message="Charger is occupied"
                description="This charger is currently occupied by another user"
                href={homePath}
            />
        );
    }

    if (connectorStatus === 'Charging') {
        return (
            <ChargingPage
                countDown={stopCountDown}
                loading={false}
                handleStopCharging={stopCharging}
            />
        );
    }

    if (
        connectorStatus === 'Preparing' ||
        (process.env.NEXT_PUBLIC_EV_MODE === 'dev' &&
            connectorStatus === 'Available') // dev mode will can start from available
    ) {
        return (
            <TokenReadyPage
                showReward={catPlateNo !== null}
                rewardCount={rewardCount}
                loading={false}
                handleStartCharging={(duration) => {
                    startCharging({
                        duration: duration,
                        userToken: userObj?.token ?? '',
                    });
                }}
            />
        );
    }

    // Final: connectorStatus === 'Available'
    return (
        <MessagePage
            message="Please plugin the charger to get started"
            href={homePath}
        />
    );
};

export default AppPage;
