'use client';
import LoadingAnimation from '@/app/components/loading';
import Image from 'next/image';
import Link from 'next/link';

const MessagePage = ({
    message,
    description,
    loading = false,
    href,
}: {
    message: string;
    description?: string;
    loading?: boolean;
    href?: string;
}) => {
    return (
        <div
            style={{ height: 'calc(100vh - 6rem)' }}
            className="flex items-center flex-col justify-between w-full"
        >
            <div className="flex flex-col items-center px-12 pt-8">
                <p className="mb-2 text-xl text-center font-bold">{message}</p>
                {description && <p className="text-center whitespace-pre-line">{description}</p>}

                {loading && (
                    <div className="flex justify-center mt-8">
                        <LoadingAnimation />
                    </div>
                )}

                {href && (
                    <div className="flex flex-col items-center gap-y-4 transform rotate-180 mt-24">
                        <Link href={href}>
                            <Image
                                width={42}
                                height={15}
                                alt="arrow right"
                                src="/arrow-right.svg"
                            />
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MessagePage;
