'use client';
import Button from '@/app/components/button';
import RewardBlock from '@/app/components/reward-block';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { FC, Fragment } from 'react';

dayjs.extend(duration);

type Props = {
    duration: number;
    handleRestart: () => void;
    onRedeem: () => void;
    handlelogout: () => void;
    rewardCount: number;
};

const ChargingPage: FC<Props> = ({
    duration,
    handleRestart,
    onRedeem,
    handlelogout,
    rewardCount,
}) => {
    const diffDuration = dayjs.duration(duration * 1000);

    const hours = diffDuration.hours();
    const minutes = diffDuration.minutes();
    const seconds = diffDuration.seconds();

    return (
        <Fragment>
            <div className="flex flex-col items-center w-full px-12 pt-4">
                <p className="mb-2 text-4xl text-center font-bold">Done</p>
                <p className="text-center flex gap-x-2">
                    Charged
                    <span className="text-[#bb834a]">
                        {hours}hr {minutes}m {seconds}s
                    </span>
                </p>

                <div className="flex flex-col mt-4 w-full">
                    <Button onClick={handleRestart} color="primary">
                        Resume
                    </Button>
                    {/* <Button onClick={onRedeem} color="primary">
                        My charging record
                    </Button> */}
                    <Button onClick={handlelogout} color="primary">
                        Logout
                    </Button>
                </div>

                {rewardCount ? (
                    <div className="mt-4">
                        <RewardBlock
                            rewardCount={rewardCount}
                        />
                    </div>
                ) : (
                    <div />
                )}
            </div>
        </Fragment>
    );
};

export default ChargingPage;
