'use client';
import Button from '@/app/components/button';
import { FC, Fragment, useEffect, useState } from 'react';

let interval: any;

type Props = {
    loading: boolean;
    handleStopCharging: () => void;
    countDown: number;
};

const ChargingPage: FC<Props> = ({ loading, handleStopCharging, countDown }) => {
    return (
        <Fragment>
            <div className="mt-20 flex flex-col items-center z-20 px-12">
                <p className="mb-2 text-2xl text-center font-bold">Charging</p>
                <p className="text-center">
                    You may close the page and wait for
                    <br />
                    the charging to complete
                </p>

                <Button
                    type="button"
                    color="primary"
                    loading={loading || countDown > 0}
                    loadingText={`${
                        countDown > 0 ? `Stop (${countDown})` : 'Stopping...'
                    }`}
                    onClick={handleStopCharging}
                >
                    Stop
                </Button>
            </div>
        </Fragment>
    );
};

export default ChargingPage;
