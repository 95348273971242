import React from 'react';
import './loading-animation.css';

const LoadingAnimation = () => {
  return (
    <div className="loading-container">
      <div className="loading-bar"></div>
      <div className="loading-bar"></div>
      <div className="loading-bar"></div>
    </div>
  );
};

export default LoadingAnimation;
