'use client';
import Button from '@/app/components/button';
import RewardBlock from '@/app/components/reward-block';
import { FC, useState } from 'react';

type Props = {
    showReward: boolean;
    loading: boolean;
    handleStartCharging: (duration: number) => void;
    rewardCount: number;
};

const TokenReadyPage: FC<Props> = ({
    showReward = false,
    loading,
    handleStartCharging,
    rewardCount,
}) => {
    const [duration, setDuration] = useState<number>(3);

    return (
        <div
            style={{ height: 'calc(100vh - 6rem)' }}
            className="flex items-center flex-col justify-between w-full"
        >
            <div className="flex flex-col items-center px-12 pt-4">
                <p className="mb-2 text-xl text-center font-bold">
                    Choose your charging duration
                </p>

                <div className="mt-5 w-full max-w-96">
                    <div className="border border-black rounded-lg p-2 mt-1">
                        <select
                            defaultValue={'3'}
                            className="w-full border-none outline-0"
                            id="duration"
                            onChange={(e) =>
                                setDuration(parseInt(e.target.value))
                            }
                        >
                            <option value="1">1 Hour</option>
                            <option value="2">2 Hours</option>
                            <option value="3">3 Hours</option>
                        </select>
                    </div>
                </div>
                <div className="mt-3 w-full max-w-96">
                    <Button
                        loading={loading}
                        loadingText={'Loading...'}
                        onClick={() => handleStartCharging(duration)}
                        color="primary"
                    >
                        Start
                    </Button>
                </div>

                {showReward ? (
                    <div className="mt-6">
                        <RewardBlock
                            rewardCount={rewardCount}
                        />
                    </div>
                ) : (
                    <div />
                )}
            </div>
        </div>
    );
};

export default TokenReadyPage;
